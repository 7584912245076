import React from "react"
import { graphql, Link } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import Widgets from '../components/widgets/Widgets' 
import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function Fees({ data, location }) {
  const page = data.allWpPage.nodes[0];

  return (
    <Layout className="page-fees">	
      <Seo post={page} />
	  <article>
	    <header className="page-widgets">
	      <Hero widget={{layout : 'inner', title: (page.title), content: page.content}} />
	    </header>
		<div className="internal">
			{page.fees?.feesTable?.length && (
				<>
					<section className="fees-table">
						<table>
							<thead>
								<tr>
								    <th>Property Price</th>
								    <th>Standard</th>
								    <th>Small HMO</th>
								    <th>Large HMO/MUFB</th>
								</tr>
							</thead>
							<tbody>
							  {page.fees.feesTable.map((row, i) => (
								<tr key={`row${i}`}>
									<td className="property-price">{row.propertyPrice}</td>
									<td className="cost">
									  {
										row.standard.useQuoteLink
										? <Link to={page.fees.quoteLink.url}>{page.fees.quoteLink.title}</Link>
										: row.standard.value
									  }
									</td>
									<td className="cost">
									  {
										row.smallHmo.useQuoteLink
										? <Link to={page.fees.quoteLink.url}>{page.fees.quoteLink.title}</Link>
										: row.smallHmo.value
									  }
								    </td>
									<td className="cost">
									  {
										row.largeHmomufb.useQuoteLink
										? <Link to={page.fees.quoteLink.url}>{page.fees.quoteLink.title}</Link>
										: row.largeHmomufb.value
									  }
									</td>
								</tr>
							  ))}
							</tbody>
						</table>					
					</section>
					
					<section className="fees-list">		
						<ul>
						  {page.fees.feesTable.map((row, i) => (
							<li key={`row${i}`}>
								<p className="property-price">Property price<strong>{row.propertyPrice}</strong></p>
								<dl className="bottom">
									<div>
										<dt>Standard</dt>
										<dd>
										  {
											row.standard.useQuoteLink
											? <Link to={page.fees.quoteLink.url}>{page.fees.quoteLink.title}</Link>
											: row.standard.value
										  }
										</dd>
									</div>
									<div>
										<dt>Small HMO</dt>
										<dd>
										  {
											row.smallHmo.useQuoteLink
											? <Link to={page.fees.quoteLink.url}>{page.fees.quoteLink.title}</Link>
											: row.smallHmo.value
										  }
										</dd>
									</div>
									<div>
										<dt>Large HMO/MUFB</dt>
										<dd>
										  {
											row.largeHmomufb.useQuoteLink
											? <Link to={page.fees.quoteLink.url}>{page.fees.quoteLink.title}</Link>
											: row.largeHmomufb.value
										  }
										</dd>
									</div>
								</dl>
							</li>
						  ))}
						</ul>
					</section>
				</>
			)}
			<section className="notes" dangerouslySetInnerHTML={{ __html: page.fees.copy }} />
		</div>
		<Widgets widgets={page.widgets?.pageWidgets} />
		
	  </article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
	    content
        ...PageSEOFragment
	    fees {
	      feesTable {
	        propertyPrice
	        standard {
	          useQuoteLink
	          value
	        }
	        smallHmo {
	          useQuoteLink
	          value
	        }
	        largeHmomufb {
	          useQuoteLink
	          value
	        }
	      }
	      quoteLink {
	        target
	        title
	        url
	      }
	      copy
	    }
		...WidgetFragment
      }
    }
  }
`